import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CallToAction from "../components/CallToAction"
import { BreadCrumb, MarkdownContent } from "../components/Section"
import generateHTML from "../utils/generateHTML"


const Wrapper = styled.div`
  position: relative;
  padding-top: 250px;
  padding-bottom: 70px;
  max-width: 890px;
  width: 100%;
  margin: auto;
  & .gatsby-image-wrapper {
    width: 100%;
    margin-bottom: 35px;
  }
  @media (max-width: 900px) {
    padding: 170px 5%;
  }
  @media (max-width: 480px) {
    padding: 170px 10px 50px;
  }
`
class caseStudiesTemplatePage extends React.Component {
	render() {
    const pageData = this.props.data.contentfulCaseStudy
		return(
			<Layout location={this.props.location}>
          <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
            <Wrapper>
              <BreadCrumb mTop="130px">
                  <Link to="/">Home</Link> / <Link to="/case-studies">Case Studies</Link> / <span>{pageData.title}</span>
              </BreadCrumb>
              <h1>{pageData.title}</h1>
              <Img
                fluid={pageData.thumbnail.fluid}
                alt={`thumbnail for ${pageData.title}`}
              />
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </Wrapper>
          <CallToAction />
      </Layout>
		)
	}
}
		
export default caseStudiesTemplatePage

export const pageQuery = graphql`
  query caseStudiesTemplateQuery($id: String!) {
    contentfulCaseStudy(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      thumbnail {
        fluid(maxWidth: 980) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      content {
        content
      }
    }
  }
`
